import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import persist from 'pinia-plugin-persistedstate'

import App from './App.vue'
import router from '@/router'
import { i18n } from '@/i18n'
import 'vant/lib/index.css';

// 非生产环境 打开vconsole
import VConsole from 'vconsole';
if (import.meta.env.VITE_ENV !== 'prd') {
    // new VConsole()
    console.log(location.href);
}


import Vant from 'vant';

import '@vant/touch-emulator';
const app = createApp(App)
app.use(createPinia().use(persist))
app.use(router)
app.use(i18n);
app.use(Vant)


app.mount('#app')
