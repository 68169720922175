// zh.js
export default {
  lang: {
    // 会员码页面
    home: '首页',
    normalMember: 'PC',
    vipMember: 'VIP会员',
    superVip: 'Super VIP会员',
    deluxeClub: 'Deluxe Club会员',
    earnedPoints: '所得积分',
    membershipCode: '会员码',
    perpetual: '永久',
    accumulatedConsumption: '累计消费',
    accumulatedConsumption2: '累计再消费',
    todaySpending: '或单次消费',
    upgradeLevel: '升级至',
    extend: '延续',
    validityPeriod: '有效期至',
    currentConsumption: '当前消费',
    backBtn: "返回",
    grading: "已符合续会一年资格",
    upgrade: "升级",
    privacyTitle: "合并协议细则",
    consumetips: "详情请参照消费记录",
    RMB: "元",
    notFind1: "抱歉！页面丢失啦~",
    notFind2: "建议退出(我的)，重新进入",
    // 合并会员
    mergePage: {
      menu: "合并账户",
      autoMerge: "检测到门市账号",
      account: "卡号",
      name: "名称",
      sex: "性别",
      mergeBtn: "合并",
      cancelBtn: "不合并",
      find: "可合并账号",
      storeOffline: "港澳门店",
      storeOnline: "港澳网店",
      applet: "海淘小程序",
      homeBtn: "去合并",
      tips: "发现其他SASA账号，可以进行合并",
      birthday: "生日",
      year: "年",
      month: "月",
      day: "日",
      afterTitle: "合并后",
      level: "等级",
      points: "积分结余",
      privacy: "《会员帐户合并》",
      privacy1: "我已阅读及同意",
      privacy2: "条款。",
      privacy3: "我已阅读及同意莎莎的私隐政策。",
      privacy4: "我同意莎莎使用我的个人资料进行直接促销。",
      confirm: "确认合并",
      waring1: "请注意，账戶合并过程不可逆转。",
      waring2: "点击“同意”按钮，默认您个人信息可在SASA集团旗下的所有平台进行共享，共享内容包括但不限于：个人信息、积分、会员等级、订单不等。",
      mergeSuccess: "合并成功",
      privacyMarks: "您曾登记超过1个莎莎会员帐户（以登记手机号码为准），请进行会员帐户合并，享受更好的购物体验。",
      privacyTitle: "《会员帐户合并》条款及细则",
      privacy_1: "会员帐户合并后，莎莎会把您的优惠券及积分合并，您的会员权益不会受到影响。",
      privacy_2: "会员帐户合并后，您可以使用已合并的会员帐户于门市及网店进行购物同时赚取积分。",
      privacy_3: "帐户一经合并无法取消。如需查询，请联络莎莎客户服务热线：（852）2505 5023",
      monthRange: "3个月内",
      yearRange: "1年內",
      expiresOn: "有效期",
      errorTitle: "生日信息错误",
      error1: "很抱歉，您填写的",
      error2: "生日信息错误，无法合并，请修改成正确的生日信息",
      gradingText1: "一年内累计消费",
      gradingText2: "可延续贵宾会籍多一年：",
      gradingText3: "你仍需消费",
      gradingText4: "才可续会",
    },

    // 积分页面
    myPoints: "我的积分",
    pointsFlow: '积分流水',
    availableIntegral: '积分结余',
    pointDetail: '积分明细',
    ofwhich: '其中',
    points: '积分',
    will: '将在',
    expired: '过期',

    // 历史优惠券
    historicalCoupon: '历史优惠券',
    all: '全部',
    used: '已使用',
    alreadyExpired: '已过期',

    // 购物记录
    orderLists: '购物记录',
    originOrderCode: '订单号码',
    actualPayment: '实际付款',
    orderGoodsName: '订单商品名',
    pleaseEnterGoodsName: '请输入商品名',
    orderType: '订单类型',
    order: '购物单',
    specialOrder: '正负单',
    refundOrder: '退款单',
    orderDate: '订单日期',
    nearlyAMonth: '近1个月',
    nearlyThreeMonths: '近3个月',
    nearlySixMonths: '近6个月',
    startDate: '开始时间',
    endDate: '结束时间',
    orderSource: '订单来源',

    // 优惠券
    myCoupon: '我的优惠券',
    couponDetail: '优惠券详情',
    receiveCoupon: '领取优惠券',
    couponClaim: '领券中心',
    couponCode: '券Code',
    please: '请于',
    beforeUse: '前使用',
    usedFor: '适用于',
    useInstructions: '使用说明', 

    //按钮 
    ok: '确认',
    cancel: '取消',
    submit: '提交',
    close: '关闭',
    reset: '重 置',
    query: '查 询',
    confirmedUse: '立即使用',
    claim: '立即领取',
    distribute: '系统处理中',
    copy: "复制",
    finish: "完成",
    mergeFinish: "合并完成",
    agree: "同意",
    writeOff: "核销",
    modify: "修改",
    // 提示
    copySuccess: '复制成功',
    copyFail: '复制失败',
    noMore: '没有更多',
    confirmUseMsg: '确认使用后，优惠券将不可退回，请确认是否现在使用？',
    useSuccessfully: '使用成功！',
    receiveSuccess: '领取成功！',
    submitSuccess: '提交成功！',
    editSuccess: '修改成功！',
    // 标题
    tip: '提示',
    filter: '筛选',
    selectStartDate: '选择开始日期',
    selectEndDate: '选择结束日期',
    chinaOnline: '海淘小程序',
    chinaOffline: '大陆分店',
    hongkongOnline: '港澳网店',
    hongKongOffline: '港澳分店',
    usePlatform: '选择可用优惠券的平台',
    // 沒用的
    coupons: '优惠券',
    couponHistory: '历史券',


    // 抽奖
    lottery: '抽奖',
    prizeDetails: '奖品详情',
    lotteryStart: '开始抽奖',
    lotteryNum: {
      first: '您现在有',
      center: '次',
      last: '抽奖机会'
    },
    lotteryTipOne: '所有抽奖机会一次性消耗',
    lotteryTipTwo: '抽奖机会当天有效',
    redemptionInfo: '兑换资料',
    winning: '恭喜！您抽中了',
    participation: '谢谢参与',
    phoneNumber: '电话号码',
    orderNo: '订单号',
    actualPrice: '实付金额',
    exchangeDate: '兑换日期',
    myPrize: '我的奖品',
    prizeClaim: '去领取',
    prizePlease: '请',
    pickItUpBy: '号前领取使用',
    receiveTime: '领取时间',
    expirationDate: '领取有效期',
    view: '查看',
    real: '实物', 
    backHome: '返回首页',
    congratulations: '恭喜您获得了',
    selectStore: '选择门店',
    pleaseSelectStore: '请选择门店',
    selectRegionMsg: '请先选择区域',
    before: '前',
    storeName: '门店名称',
    storeAddress: '门店地址',
    prizeNo: '奖品单号',
    confirmClaim: '确定已领取',
    giftBoxLottery: '惊喜盒子抽奖',
    joinLottery: '参加抽奖',
    confirmMsg: '一经确认则默认已领奖，请确保已领取后再点击确认',
    couponCode: '优惠券码',
  },
  exchange: {
    placeholder: '请输入',
    phonePlaceholder: '请输入手机号',
    phoneError: '手机号格式不正确',
    areaCode: '地区编号',
    phone: '电话号码',
    submit: '提交',
    myPrize: '我的奖品',
    activityRule: '奖品详情',
    joinActivity: '立即参加',
    exchangeLottery: '兑换抽奖机会',
    ticketInfo: '请提供小票信息',
    pleaseProvideExchangeOrder: '请提供兑换订单',
    chooseOne: '(2选1)',
    marketTicket: '门市发票',
    onlineTicket: '网店发票',
    orderDate: '订单日期',
    defaultToday: '(默认当天)',
    invoiceImageTip: '【购物单编号TG后的12位数字】',
    orderImageTip: '【发票编号样本】',
    invoiceCode: '发票编码',
    orderSn: '订单号',
    actualPayment: '实付金额',
    exchangeInfo: '确认兑换信息',
    hmOnline: '港澳线上',
    hmStore: '港澳门店',
    exchangeLotteryNum: '可兑换抽奖数',
    provideExchangeOrder: '提供兑换订单',
    pleaseSelectOrder: '请选择订单',
    orderSelect: '订单选择',
    selectOrder: '选择订单',
    manualInput: '手动录入',
    confirm: '确定',
    // 条款描述
    clauseDesc: '我了解此活动的详情并同意及接受上述有关条款及细则，包括收集个人资料声明及相关条款，并同意。',
    // 权限描述
    permissionDesc: '我不同意将我的个人资料用作服务推广或宣传之用途。',
    noOrderTips: '订单号不能为空',
    orderFailTips: '订单号格式错误',
    lotteryNumTips: '可兑换抽奖数为0',
    checkboxTips: '请勾选协议',
    exchangeMsg: '是否确定兑换？',
    exchangeFailMsg: '抱歉，今日你已经参加过兑换',
    exchangeSuccess: '兑换成功',
    deadline: '来晚了，活动已结束咯！',
    notStart: '抱歉，活动未开始。',
    privacy: '条款与细则',
    OCRamount: "请按示意图拍照，或者人工输入消费金额",
  },
  // 会员登记
  eForm: {
    home: "会员登记",
    loginForm: {
      title: "激活莎莎贵宾卡/会员卡帐户",
      account: "莎莎贵宾卡/会员卡号码",
      accountPla: "请输入卡号",
      phone: "已登记手机号码",
      phonePla: "请输入手机号码",
      notes: "如忘记以上必填数据，请联络莎莎客户服务热线（852）25055023",

      accountTips1: "实体莎莎贵宾卡",
      accountTips2: "莎莎贵宾卡背面之会员号码",
      accountTips3: "电子莎莎贵宾卡/会员卡",
      accountTips4: '“SaSaHK”应用程序内（按右上角之会员卡图标），于二维码上之贵宾卡/会员卡号码',

      emptyError: "请填写会员信息",
    },
    infoForm: {
      nameCn: "会员姓名 (中文)",
      nameEn: "会员姓名 (英文)",
      mr: "先生",
      mrs: "女士",
      birthday: "生日日期",
      month: "月月",
      year: "年年",
      phone: "如想修改登记手机号码，请于下列输入新手机号码",
      agree1: "本人不同意莎莎使用提供的个人资料以用作市场直接营销推广之用。",
      agree2: "本人已阅读并同意莎莎私隐政策。 (link: https://corp.sasa.com/tc/global/privacy-policy.php)",
      successMsg: "谢谢！您的莎莎贵宾卡/会员卡帐户已经确定，如需更改资料，请联络莎莎客户服务热线（852）25055023",
      agree2Tips: "请阅读并同意莎莎私隐政策!",
    },
    btnOK: "确认",
    btnNext: "下一步",
    btnFinish: "完成",
  },
  // OMO优惠券
  omoCoupon: {
    history: "已用/过期",
    couponCode: "券Code",
    expired: "已过期",
    writtenOff: "已使用",
    all: "全部",
    notChange: "选择后不可更改优惠券使用平台！",
    tips: "提示",
    minute: "分钟",
    receiveSuccess: '领取成功',
    pendingRow1: "系统正在处理",
    pendingRow2: "您领取的优惠券",
    pendingRow3: "成功领取的优惠券将于【我的优惠券】中显示",
    receiveFailed: "领取失败",
    failedtext1: "請再按立即領取",
    failedtext2: "重新領取",
    choose: "选择",
    inputRemark: "请任选一下一种组合方式填写生日",
    year: "年",
    month: "月",
    day: "日",
    afterReceive: "领取后",
    effectiveDays: "天有效",
    orderChannel: "订单渠道",
    orderDate: "订单日期",
  },
  // 无数据
  noData: {
    myCoupon: "没有优惠券可使用",
    receiveCoupon: "没有优惠券可以领取",
    historyCoupon: "没有历史优惠券",
    order: "没有订单记录",
    point: "没有积分流水",
  },
  // 会员等级
  memberLevel: {
    PC: "普通会员",
    VIP: "贵宾会员",
    P2: "贵宾会员",
    P4: "贵宾会员",
  },
  // 渠道
  channel: {
    EZR: "海淘小程序",
    BOSS: "港澳门市",
    '91APP': "港澳网店",
  },
  // 积分类型
  pointsType: {
    getFromOrder : '消费获得',
    payForOrder : '消费扣减',
    signIn : '签到',
    give : '赠送',
    exchange : '兑换',
    refundExchange : '兑换退单',
    refundFromOrder : '订单退单(订单获得)',
    refundForOrder : '订单退单(订单使用)',
    Other : '其他',
  },
}
