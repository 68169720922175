import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { vantLocales } from '@/i18n'

export const useSystemStore = defineStore('store', () => {
  const loading = ref(false)
  const lang = ref('')
  const params = ref({})
  const isiPad = ref(false);
  const pageType = ref("");

  const isEn = computed(() => lang.value === 'en')
  const isPc = computed(() => {
    const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return !flag
  })
  const isPhone = computed(() => {
    let info = navigator.userAgent;
    return /mobile/i.test(info) && !/iPad/i.test(info);
  })
  function setLoading(value: boolean) {
    loading.value = value
    setTimeout(() => {
      loading.value = false
    }, 2000);
  }
  function setBaseParams(obj: any) {
    // TODO：EZR 91APP 的token 中的+号被浏览器去掉了，每个空格都要换成 + 号
    if (["91APP", "EZR"].includes(obj?.channelCode)) {
      obj.token = obj?.token?.replace(/\s/g, "+");
    }
    params.value = obj;
  }
  function setLang(value: string) {
    lang.value = value;
    vantLocales(value); // 切换 Vant 语言
  }

  function setiPad(value: boolean) {
    isiPad.value = value;
  }

  function setPageType(value: any) {
    pageType.value = value;
  }

  function reset() {
    loading.value = false;
    lang.value = '';
    params.value = {};
    isiPad.value = false;
    pageType.value = "";
  }

  return {
    loading,
    params,
    isEn,
    isPc,
    isPhone,
    setLoading,
    setBaseParams,
    setLang,
    lang,
    isiPad,
    setiPad,
    setPageType,
    pageType,
    reset,
  }
},
  {
    persist: true,
  })
