// en.js
export default {
  lang: {
    // 会员码页面
    home: 'HOME',
    normalMember: 'Ordinary Member',
    vipMember: 'VIP',
    superVip: 'Super VIP',
    deluxeClub: 'Deluxe Club',
    earnedPoints: 'Points Earned',
    membershipCode: 'Member Code',
    perpetual: 'Permanent',
    accumulatedConsumption: 'Accumulated purchases',
    accumulatedConsumption2: 'Accumulated re purchases',
    todaySpending: 'or Single purchase',
    upgradeLevel: 'Upgrade to ',
    validityPeriod: 'Effective Date',
    currentConsumption: 'Current purchases',
    backBtn: "Back",
    grading: "Eligible for level upgrade",
    upgrade: "Upgrade",
    extend: "Extend ",
    privacyTitle: "Agreement Details",
    consumetips: "Spending detail please refer to order record ",
    RMB: "RMB ",
    notFind1: "sorry! The page is missing",
    notFind2: "Suggest exiting(mine) and re entering",
    // Merge member
    mergePage: {
      menu: "Account consolidation",
      autoMerge: "Detect store account",
      account: "Card No",
      name: "Name",
      sex: "Gender",
      mergeBtn: "Consolidate",
      cancelBtn: "Do not merge",
      find: "Accounts detected",
      storeOffline: "Hong Kong/Macau store",
      storeOnline: "Hong Kong/Macau online",
      applet: "E-commerce applet",
      homeBtn: "Account consolidation",
      tips: "Discover other SASA accounts to merge",
      birthday: "Birthday",
      year: "Year",
      month: "Month",
      day: "Day",
      afterTitle: "After consolidation",
      level: "Tier",
      points: "Points Balance",
      privacy: '"Member Account Consolidation" ',
      privacy1: "I have read and agreed to the ",
      privacy2: " Terms and Conditions.",
      privacy3: "I have read and agreed to Sa Sa’s Privacy Policy.",
      privacy4: "I consent to Sa Sa using my personal data in direct marketing.",
      confirm: "Confirm consolidation",
      waring1: "Please note that account consolidation cannot be cancelled once confirmed.",
      waring2: 'By clicking the "Agree" button, you are consenting that your personal information can be shared across all platforms under SASA Group. The shared content includes, but is not limited to personal information, points, member tiers, and order details.',
      mergeSuccess: "Merge successful",
      privacyMarks: "You have registered more than 1 Sa Sa membership account (based on your registered mobile number). To enjoy better shopping experience, please proceed with the consolidation of your membership accounts.",
      privacyTitle: '"Member Account Consolidation" Terms and Conditions',
      privacy_1: "After the consolidation of your membership accounts, Sa Sa will merge your coupons and points, your membership benefits will not be affected.",
      privacy_2: "After the consolidation of your membership accounts, you can use the consolidated membership account to shop and earn points at stores and eshop.",
      privacy_3: "Once the accounts have been consolidated, it cannot be cancelled. If you have any inquiries, please contact the Sa Sa customer service hotline: (852) 2505 5023.",
      monthRange: "Within 3 months ",
      yearRange: "Within 1 years ",
      expiresOn: "Expires on",
      errorTitle: "Incorrect birthday information",
      error1: "We are sorry that the",
      error2: "birthday information you provided does not match with our system data, therefore the account consolidation is unsuccessful. Please update with the correct birthday information.",
      gradingText1: "Spend",
      gradingText2: "to extend next year VIP membership:",
      gradingText3: "You have to spend",
      gradingText4: "$xxx to reach $300",
    },

    // 积分页面
    myPoints: "My Points",
    pointsFlow: 'Points Flow',
    availableIntegral: 'Points Balance',
    pointDetail: 'Points Detail',
    ofwhich: '',
    points: 'points',
    will: 'will expire on',
    expired: ' ',

    // 历史优惠券
    historicalCoupon: 'Historical Coupon',
    all: 'All',
    used: 'Used',
    alreadyExpired: 'Expired',

    // 购物记录
    orderLists: 'Purchase History',
    originOrderCode: 'Receipt No.',
    actualPayment: 'Total Paid',
    orderGoodsName: 'Product Name',
    pleaseEnterGoodsName: 'Please enter the product name',
    orderType: 'Order Type',
    order: 'Purchase Completed',
    specialOrder: 'Special Order',
    refundOrder: 'Cancel/Refund',
    orderDate: 'Order Date',
    nearlyAMonth: 'Past 1 months',
    nearlyThreeMonths: 'Past 3 months',
    nearlySixMonths: 'Past 6 months',
    startDate: 'Start time',
    endDate: 'End time',
    orderSource: 'Order Source',

    // 优惠券
    myCoupon: 'My Coupons',
    couponDetail: 'Coupon Details',
    receiveCoupon: 'Coupon Details',
    couponClaim: 'Coupon Center',
    couponCode: 'Coupon Code',
    please: 'Please use by',
    beforeUse: '',
    usedFor: 'Available Platforms',
    useInstructions: 'Instructions for use',

    //按钮
    ok: 'Confirm',
    cancel: 'Cancel',
    close: 'Close',
    reset: 'Reset',
    query: 'Query',
    confirmedUse: 'Redeem now',
    claim: 'Grab Now',
    distribute: 'In Progress',
    copy: "Copy",
    finish: "Finish",
    mergeFinish: "Done",
    agree: "Agree",
    writeOff: "Use",
    // 提示
    copySuccess: 'Copy successfully',
    copyFail: 'Copy failed',
    noMore: 'No more transactions',
    confirmUseMsg: 'Coupon cannot be returned once redeemed. Please confirm if you would like to use it now.',
    useSuccessfully: 'Use sucessfully！',
    receiveSuccess: 'Claim sucessfully！',
    editSuccess: 'Edit Sucessfully！',

    // 标题
    tip: 'Tips',
    filter: 'Filter',
    selectStartDate: 'Select start date',
    selectEndDate: 'Select end date',
    chinaOnline: 'MC eshop',
    chinaOffline: 'MC store',
    hongkongOnline: 'HK/MO store',
    hongKongOffline: 'HK/MO eshop',
    usePlatform: 'Select a platform for redemption',

    
    // 沒用的
    coupons: 'Discount coupon',
    couponHistory: 'Coupon record',


    // 抽奖
    lottery: 'Lottery',
    prizeDetails: 'Prize details',
    lotteryStart: 'Start the draw',
    lotteryNum: {
      first: 'You now have',
      center: 'time',
      last: 'Chance for prize drawing'
    },
    lotteryTipOne: 'All lottery opportunities will be consumed at one time',
    lotteryTipTwo: 'Lucky draw is valid on the same day', 
    redemptionInfo: 'Exchange information',
    winning: "Congrats! You've won",
    participation: 'Thank your participation',
    phoneNumber: 'Phone number',
    orderNo: 'Order number',
    actualPrice: 'The amount actually paid',
    exchangeDate: 'Conversion date',
    myPrize: 'My prize',
    prizeClaim: 'Go and claim',
    prizePlease: 'Please',
    pickItUpBy: 'Prior access',
    receiveTime: 'Draw time',
    expirationDate: 'Collection period',
    view: 'look over',
    real: 'In kind', 
    backHome: 'Back homepage',
    congratulations: 'Congratulations on your win',
    selectStore: 'Select store',
    pleaseSelectStore: 'Please select a store',
    selectRegionMsg: 'Please select a region first',
    before: 'Front',
    storeName: 'Store name',
    storeAddress: 'Store address',
    prizeNo: 'Prize number',
    confirmClaim: 'Confirmed claim',
    giftBoxLottery: 'Surprise box draw',
    joinLottery: 'Participate in the lottery',
    confirmMsg: 'Once confirmed, you have received the prize by default. Please make sure that you have received the prize before clicking Confirm',
    couponCode: 'Coupon code',
  },
  exchange: {
    placeholder: 'Please enter the ',
    // 请输入手机号
    phonePlaceholder: 'Please enter the phone number',
    // 手机号格式不正确
    phoneError: 'The phone number format is incorrect',
    // 地区编号
    areaCode: 'Area Code',
    // 手机号码
    phone: 'Phone Number',
    submit: 'Submit',
    // 我的奖品
    myPrize: 'My Prize',
    // 奖品详情
    activityRule: 'Prize Details',
    // 立即参加
    joinActivity: 'Join Activity',
    // 兑换抽奖机会
    exchangeLottery: 'Exchange Lottery Chances',
    // 请提供小票信息
    ticketInfo: 'Provide Ticket Info',
    // 请提供兑换订单
    pleaseProvideExchangeOrder: 'Please provide exchange order',
    chooseOne: '(Choose One)',
    // 门市小票
    marketTicket: 'Market Ticket',
    // 网店小票
    onlineTicket: 'Online Ticket',
    // 订单日期
    orderDate: 'Order Date',
    // 默认当天
    defaultToday: '(Default Today)',
    // 发票图片提示
    invoiceImageTip: '[12 digits after the list number TG]',
    // 订单图片提示
    orderImageTip: '[Invoice Number All information]',
    // 发票编码
    invoiceCode: 'Invoice Code',
    // 订单号
    orderSn: 'Order Sn',
    // 实付金额
    actualPayment: 'Actual Payment',
    // 确认兑换信息
    exchangeInfo: 'Confirm Exchange Information',
    // 港澳线上
    hmOnline: 'HK/MO eshop',
    hmStore: 'HK/MO store',
    // 可兑换抽奖数
    exchangeLotteryNum: 'Available Lottery Chances',
    // 提供兑换订单
    provideExchangeOrder: 'Provide Exchange Order',
    // 请选择订单
    pleaseSelectOrder: 'Please Select Order',
    // 订单选择
    orderSelect: 'Order Select',
    // 选择订单
    selectOrder: 'Select Order',
    // 手动录入
    manualInput: 'Manual Input',
    // 确定
    confirm: 'Confirm',
    // 条款描述
    clauseDesc:
      'I understand the details of this activity and agree to and accept the above terms and conditions, including the Personal Data Collection Statement and related terms, and agree.',
    // 权限描述
    permissionDesc:
      'I do not consent to the use of my personal data for promotional or promotional purposes.',
    noOrderTips: 'Order number cannot be empty',
    orderFailTips: 'Order number format error',
    lotteryNumTips: 'Available Lottery Chances are 0',
    checkboxTips: 'Please agree to the above terms and conditions',
    exchangeMsg: 'Are you sure to exchange?',
    exchangeFailMsg: 'Sorry, you have already participated in the exchange',
    exchangeSuccess: 'Exchange Success',
    deadline: 'Sorry, the activity has ended',
    notStart: 'Sorry, the activity not start',
    privacy: 'Terms and Conditions',
    OCRamount: "Please take photos according to the diagram, or manually input the consumption amount",
  },
  // 会员登记
  eForm: {
    home: "Member Activate",
    loginForm: {
      title: "Activation of Sa Sa VIP Card / Member Card membership",
      account: "Card Number",
      accountPla: "Input Card Number",
      phone: "Registered mobile number",
      phonePla: "Input mobile Number",
      notes: "Please contact Sa Sa Customer Service support at (852) 25055023 if you have forgotten the requested information.",

      accountTips1: "Physical Sa Sa VIP Card",
      accountTips2: "Card number printed at the back of the SaSa VIP Card",
      accountTips3: "Electronic Sa Sa VIP Card / Member Card",
      accountTips4: 'Login to "SaSaHK" APP and click on the "Card" icon displayed on the top right corner, the Card number displayed above the QR code.',

      emptyError: "Please fill in member information",

      E0001: "The member number / mobile number entered is incorrect. Please contact Sa Sa Customer Service support at (852) 25055023 if you need further assistance.",
      E0002: "Your Sa Sa VIP Card / Member Card account is activated. Please contact Sa Sa Customer Service support at (852) 25055023 if you need to make further changes to your account.",
      E0003: "The mobile number entered is already registered via Sa Sa BOC Card. Please enter another mobile number. If no information was received by 20 June, Sa Sa would proceed to combine your accounts into one. Please contact Sa Sa Customer Service support at (852) 25055023 if you need further assistance.",
      E0004: "Please enter your personal mobile number.",
      E0005: "The mobile number entered has been registered. Please contact Sa Sa Customer Service support at (852) 25055023 to make changes to your account.",
      E0006: "The mobile number entered is already registered.  Please enter another mobile number. If no information was received by 20 June, Sa Sa would proceed to combine your accounts into one. Please contact Sa Sa Customer Service support at (852) 25055023 if you need further assistance.",
    },
    infoForm: {
      mr: "Mr.",
      mrs: "Ms.",
      nameCn: "Name of Member (Chinese)",
      nameEn: "Name of Member (English)",
      birthday: "Date of Birth",
      year: "YYYY",
      month: "MM",
      phone: "Please enter the NEW mobile number if you would like to update registered mobile number",
      agree1: "I agree to Sa Sa using my personal data in direct marketing.",
      agree2: "I have read and agreed to the Sa Sa's Privacy Policy.",
      agreelink: "https://corp.sasa.com/tc/global/privacy-policy.php",
      bocPhone: "(Not applicable to Sa Sa BOC Card members)",
      successMsg: "Thank you! Your Sa Sa VIP Card / Member Card membership is activated. Please contact Sa Sa Customer Service support at (852) 25055023 if you need to make further changes to your account.",
      agree2Tips: "Please read and agree to the Sa Sa's Privacy Policy",
    },
    btnOK: "Confirm",
    btnNext: "Next Step",
    btnFinish: "Confirm",
  },
  // OMO优惠券
  omoCoupon: {
    history: "Used / Expired",
    couponCode: "Coupon Code",
    expired: "Expired",
    writtenOff: "Used",
    all: "All",
    tips: "Alert",
    minute: "minute",
    notChange: "Changes cannot be made after platform selection",
    receiveSuccess: 'Claimed',
    pendingRow1: "The system is",
    pendingRow2: "processing your coupon",
    pendingRow3: "If the relevant coupons are displayed in “My Coupons”, the coupons have been successfully claimed; if they are not displayed, please click “Grab Now” again.",
    receiveFailed: "Failed",
    failedtext1: "Please click “Grab Now” to ",
    failedtext2: "claim again",
    choose: "Choose",
    inputRemark: "Please choose one combination to fill in your birthday",
    year: "Years",
    month: "Months",
    day: "Day",
    afterReceive: "Valid for",
    effectiveDays: "days",
    orderChannel: "Order Channel",
    orderDate: "Order Date",
  },
  noData: {
    myCoupon: "No coupons available for use",
    receiveCoupon: "No coupons available to receive",
    historyCoupon: "No history coupons",
    order: "No order record",
    point: "No point flow",
  },
  // 会员等级
  memberLevel: {
    PC: "Member",
    VIP: "VIP",
    P2: "VIP",
    P4: "VIP",
  },
  // 渠道
  channel: {
    EZR: "Mini Program",
    BOSS: "Store",
    '91APP': "eShop",
  },
  // 积分类型
  pointsType: {
    getFromOrder : 'Points Earned',
    payForOrder : 'Points Used',
    signIn : 'Sign In',
    give : 'Give',
    exchange : 'Exchange',
    refundExchange : 'Exchange refund',
    refundFromOrder : 'Refund From Order',
    refundForOrder : 'refund For Order',
    Other : 'Other',
  },
}
